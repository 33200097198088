// Overrides for the base bootstrap CSS library
//
// Variables
// --------------------------------------------------

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
$padding-base-vertical:     5px;
$padding-base-horizontal:   10px;

$padding-large-vertical:    10px;
$padding-large-horizontal:  16px;

$padding-small-vertical:    5px;
$padding-small-horizontal:  10px;

$padding-xs-vertical:       1px;
$padding-xs-horizontal:     5px;

$border-radius-base:        3px;
$border-radius-large:       3px;
$border-radius-small:       3px;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base;
$btn-border-radius-large:        $border-radius-large;
$btn-border-radius-small:        $border-radius-small;

//== Forms
//
//##

//** `.form-group` margin
$form-group-margin-bottom:       10px;

//== Grid system
//
//## Define your custom responsive grid.

//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         20px;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          5px 10px;

//== Pager
//
//##

$pager-border-radius:                  15px;

//== Jumbotron
//
//##

$jumbotron-padding:              20px;

//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding:         10px;

//** Padding applied to the modal title
$modal-title-padding:         10px;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               10px;

//== Panels
//
//##

$panel-body-padding:          10px;
$panel-heading-padding:       5px 10px;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:           4px;
//** Padding around the thumbnail caption
$thumbnail-caption-padding:   9px;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:   5px;
$breadcrumb-padding-horizontal: 10px;

//== Table cell for DataSet Table
$table-cell-padding:	10px 5px 8px;
$table-border-color:	none;


$body-bg:                        #fafafa;

$btn-default-bg:                 #fafafa;

//== primary color used for link color and button backgrounds
//== adjusting due ANDI color-contrast test failure
$brand-primary: #276eab;
