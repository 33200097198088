/* Colors definitions*/

$medium-gray: #888;
$deep-blue: #153453;
$deep-deep-blue: #0A121B;
$howto-color: #6d456e;
$alerts-color: #FFC104;
$news-color: #679EF5;
$documents-color: #00BFBF;
$publications-color: #92E042;
$glossary-color: #5275ad;
$faqs-color: #868146;
$manuals-color: #644735;
$images-color: #454545;
$tools-color: #5f8168;
$mission-project-color: #35b18c;
$header-color: #aaa;
$header-link-color: #ddd;
$header-link-hover-color: white;
$search-call-to-action-color: #f0ad4e;
$search-call-to-action-color-active: #eea236;
$error-color: #a94442;
$warning-color: #d94;
$warning-color-light-background: #a45836;
$focusable-element-color: #2c73b0;
$tag-border-color:  #dceafa;
$tag-color: #ebf0f8;
$tag-color-hover: $focusable-element-color;
$zTop: 9999;
$tour-call-to-action-color: #168241;
$tour-call-to-action-hover-color: #09632d;
$refine-call-to-action-color: #eea236;
$dark-gray: #555;
$dark-blue: #123;
$very-light-grey: #f3f3f3;
$light-grey: #eee;
$med-light-grey: #aaa;
$page-background-color: #f9f9f9;

html {
  font-size: 14px;            /* Override public/lib/bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding.scss */
  height: 100%;
}

body {                        /* Override gesdisc-components - disable font-smoothing */
	-webkit-font-smoothing: auto !important;
	-moz-osx-font-smoothing: auto !important;
}

/* Make HTML5 layout elements block elements for older browsers */
header, nav, aside, article, footer, section, figure, figcaption {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  margin-bottom: 10px;
}

ul, ol {
  &.no-list-style {
    padding-left: 0px;
    li {
      list-style-type:none;
    }
  }
  &.no-list-style-padded {
    padding-left: 20px;
    li { list-style-type:none; }
  }
  &.no-list-style-spaced {
    padding-left: 0px;
    li {
      list-style-type:none;
      margin-bottom: 0.5em;
    }
  }
  &.no-list-style-indent {
    padding-left:1.5em;
    text-indent:-1.5em;
    line-height:1.6em;
  }
}

sub.beta {
  display: inline-block;
  position: relative;
  top: -1.5em;
  left: -3.1em;
  color: #9af951;
  font-size: 0.5em;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  text-shadow: 2px 2px black;
  z-index: 1;
  letter-spacing: 1px;
  transform: rotate(-7deg);
}

td, th {
  padding: 5px;
}

.uib-datepicker {
  td, th {
    padding: 0px;
  }
}

.table-striped > tbody > tr:nth-of-type(odd),
div.infoView .striped > div:nth-of-type(odd),
div.content-list.list .striped > div:nth-of-type(odd) {
  background-color: $very-light-grey;
}

tbody tr.datalist {
  border: 2px solid $deep-blue;
  border-top: 2rem solid $deep-blue;
  &::before {
    content: 'Prototype Variable Sets';
    position: absolute;
    transform: translate(0, -2rem);
    color: #eee;
    font-style: italic;
    margin-left: 5px;
    letter-spacing: 2px;
  }
}

th { background-color: white; }

hr { margin: 5px; }

.version-history {
  td, th {
    padding-right: 3em;
  }
  th {
    color:#5f5f5f;
  }
}

.monitor-table-cell-bw {
  background-color: #444444 !important;
  color: white;
  border-color: white;
  border-spacing: 2px;
  border-collapse: separate;
}

.borderless {
  border: none;
  &.btn {
    padding-top: $padding-base-vertical + 1;
    padding-bottom: $padding-base-vertical + 1;

    &.btn-xs {
      padding-top: $padding-xs-vertical + 1;
      padding-bottom: $padding-xs-vertical + 1;
    }

    &.btn-sm {
      padding-top: $padding-small-vertical + 1;
      padding-bottom: $padding-small-vertical + 1;
    }

    &.btn-lg {
      padding-top: $padding-large-vertical + 1;
      padding-bottom: $padding-large-vertical + 1;
    }
  }
}

.panel {
  border: 1px solid #ddd;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

.panel.panel-raised {
  box-shadow: 0 15px 15px -3px rgba(0, 0, 0, 0.5);
}

.panel-primary {
  border-color: $deep-blue;
  &> .panel-heading {
    border-color: $deep-blue;
    background-color: $deep-blue;
  }
}

.group-label {
  font-weight:bold;
  color:$med-light-grey;
  margin-bottom:0.9em;
}

.close-icon {
  position:absolute;
  color:#777;
  top:5px;
  right:5px;
}
.close-icon:hover {
  color:black;
  cursor:pointer;
}

.hr-dark {
  border-color: $header-color;
}

.medium-gray {
  color: $medium-gray;
}

.normal-black {
  color: black;
}

.bg-deep-blue {
  background-color: $deep-blue;
}

.bg-warning {
  background-color: $warning-color;
}

.warning-msg {
  color:$warning-color;
}
.error-msg {
  color:$error-color;
}

.btn-raised {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
  border: none;
  height: 34px;
}

.btn {
  &.btn-xs {
    font-size: 1em;
    line-height: 1em;
  }

  &.btn-sm { 
  font-size: 0.85em;	
  }
}

.list-group-item { padding: 5px 10px; }

.checkbox, .radio {
  margin-bottom: 0px !important;
  margin-top: 0px;
}

.checkbox+.checkbox,
.radio+.radio {
  margin: 0px;
}

[ng-click],
[tabindex="0"] { cursor: pointer; }

.uib-day{
	button{
		font-weight:bold;
	}
}

.text-muted{
	font-weight: normal;
}

button[disabled]{
  font-weight:normal;
   background-color: #b5b5b5;
  }
  
.btn-info.active .text-info{
	color:#fff;
}

.btn-default .text-info{
	color: #333
}

.date-picker {
  display: inline-block;
  .btn { 
	padding: 3px 8px; 
  }
}

.align-middle {
  display: inline-block;
  vertical-align: middle;
}

.align-top {
  display: inline-block;
  vertical-align: top;
}

.align-bottom {
  display: inline-block;
  vertical-align: bottom;
}

/* START - Bootstrap CSS Customizations go here */
$btn-basic-color:     #000;
$btn-basic-bg:        #e6e6e6;
$btn-basic-border:      #adadad;

.btn-basic {
  @include button-variant($btn-basic-color, $btn-basic-bg, $btn-basic-border);
}
/* END - - Bootstrap CSS Customizations */

@mixin paddings($class, $value) {
  .p-a-#{$class} { padding: $value; }
  .p-l-#{$class} { padding-left: $value; }
  .p-r-#{$class} { padding-right: $value; }
  .p-t-#{$class} { padding-top: $value; }
  .p-b-#{$class} { padding-bottom: $value; }
  .p-v-#{$class} {
    padding-top: $value;
    padding-bottom: $value;
  }
  .p-h-#{$class} {
    padding-left: $value;
    padding-right: $value;
  }
}

@mixin margins($class, $value) {
  .m-a-#{$class} { margin: $value; }
  .m-l-#{$class} { margin-left: $value; }
  .m-r-#{$class} { margin-right: $value; }
  .m-t-#{$class} { margin-top: $value; }
  .m-b-#{$class} { margin-bottom: $value; }
  .m-v-#{$class} {
    margin-top: $value;
    margin-bottom: $value;
  }
  .m-h-#{$class} {
    margin-left: $value;
    margin-right: $value;
  }
}

@include margins("clear", 0px);
@include margins("sm", 5px);
@include margins("md", 10px);
@include margins("lg", 15px);
@include margins("xlg", 20px);

@include paddings("clear", 0px);
@include paddings("sm", 5px);
@include paddings("md", 10px);
@include paddings("lg", 15px);
@include paddings("xlg", 20px);

a {
  &.no-link {
    color: inherit;
    cursor: unset;
  }

  &.no-link,
  &.accordion-toggle {
    text-decoration: none !important;
  }
}

.accordion-toggle:focus{outline: none;}

.ng-drop {
  transition: all 300ms ease-in-out;
  &.ng-enter, &.ng-leave.ng-leave-active {
    transform: translateY(-33px);
    opacity:0;
  }

  &.ng-leave, &.ng-enter.ng-enter-active {
    transform: translateY(0);
    opacity:1;
  }
}

// remove redundant animation delay on structural change
[ng-animate-ref] {
  &.ng-enter, &.ng-leave {
    &:not(.ng-anchor-in) {
      transition: all 0ms ease-in-out;
    }
  }
}

.spinner {
  position:fixed;
  top: calc(50% - 65px);
  left: calc(50% - 65px);
  background-color: rgba(0,0,0, 0.6);
  color: rgba(255,255,255,0.95);
  padding: 20px;
  z-index: 9000;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.6),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12);
}

.nasa-logo {
  background: url("../images/nasa.png") no-repeat center;
  display: inline-block;
  width: 60px;
  height: 60px;
}

.earth-bg {
  background: url("../images/gesdisc_marble.jpg") no-repeat scroll center -55px #0A121B;
  background-color: #07172a;
}

/*******************************/
/* START - main header layout  */
/*******************************/

#header {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}

#header-left {
  display: flex;
  flex-flow: row wrap;
  width: 67%;
}

.home #header-left {
  flex-flow: column nowrap
}

.logo {
/* Override gesdisc-component styles from ionic*/
  h1 {
    font-size: 36px !important;
    line-height: 1.1 !important;
    margin-right: 1em !important;
    margin-top: -0.15em;
    color:$header-link-color !important;
    padding-top:initial;
  }
  a {
    font-size: larger;
  }
}

#sci-area-popovers {
  a {
    font-size:larger;
  }
}

#util-media {
  display: flex;
  flex-flow: column nowrap;
  /* width: 225px; */
  /* margin-right: 30px; */
  button {
    font-size:larger;
    margin-left:0.4em;
    margin-right:0.4em;
  }
}

#utils, #social-media {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#social-media {
  width:10em;
}

.main-header, footer {
  padding: 15px 0px 10px 10px;
  color: $header-link-color;
  /*min-width:48em;*/

  .popover {
    color: $text-color;
    /* min-width: 300px; */
    margin: 5px;
  }

  h1 {
    margin-right: 0px;
    font-weight: normal;
    a {
      color: inherit;
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
    /* letter-spacing: -0.01em; */
  }

  h5 {
    color: inherit;
  }

  .login {
    display:flex;
    flex-direction:column;
    align-items:flex-end;
    margin-right:2em;
    margin-top:-0.55em;
    a {
      font-size:larger;
    }
  }

  #dashboard {
    font-size:larger;
  }

  [popover-class~="science-popover"] {
    color: #ddd !important;
    text-decoration:underline;
    :hover {
      color: white !important;
    }
  }

  .search-bar-small  {
    width: 65%;
    margin-top: 4px;
    min-width:5em;
  }

  .home-icon {
    font-size:1.3em;
  }

  &.home {
    min-height:38em;
  }

  &.home .search-bar {
    background-color: rgba(0,0,0, 0.3);
    margin: -120px auto 1% auto;
    padding: 50px;
    border-radius: 3px;

    &.ng-hide, &.ng-leave-active {
      background-color: rgba(0,0,0,0);
      width: 56%;
      margin: 0% 21%;
      padding: 0;
      opacity: 0;
    }

    > h2 { display: block; }

    form {
      width: 100%;
    }

    button.tabs {
      border:1px solid green !important;
    }

    .tabs {
      border-bottom: none;
      margin-bottom:0px;
      .btn.tab {
        @extend .btn-transparent-light;
        @extend .btn.btn-sm;
        margin-bottom:4px;
        border:none !important;
        border:1px solid $header-color !important;
        border-radius:0;
        &.active-tab {
          border-bottom: 1px solid $header-color !important;
          background-color:white !important;
        }
      }
    }
  }
}

.promo-link {
  display: inline-block; 
  margin: 0.3em;
  font-size: large;
  a {
    color: $search-call-to-action-color-active;
    white-space:nowrap;
    font-size:larger;
  }
  a:hover {
    color: $search-call-to-action-color;;
  }
}

/*****************************/
/* END - main header layout  */
/*****************************/

.tooltip.search-bar-tooltip > .tooltip-inner {
  background-color:#eee;
  color:#333;
  opacity:1.0;
  box-shadow: 0 6px 12px #333;
}
.tooltip.search-bar-tooltip > .tooltip-arrow {
  border-bottom-color:#eee;
  border-top-color:#eee;
}
.tooltip.search-bar-text-entry-tooltip > .tooltip-inner {
  background-color:#eee;
  color:#333;
  opacity:1.0;
  box-shadow: 0 6px 12px #333;
  width:20em !important;
  max-width:20em !important;
}
.tooltip.search-bar-text-entry-tooltip > .tooltip-arrow {
  border-bottom-color:#eee;
  border-top-color:#eee;
}

/********************************************/
/*       CSS table for centering search-bar */
/********************************************/

.home-css-table-wrapper {
  height: inherit;
  display: table;
  width: 100%;
  position: absolute;
  padding-bottom:80px;
  left: -0.1em;
}

.home-css-table-main {
  position: relative;
  height: 100%;
  display: table;
  width: 100%;
}

.home-css-table-box {
  display: table-cell;
  vertical-align: middle;
}

.home-css-table-sidebar {
  width: 100px;
}

.home-css-table-content {
  width: 54%;
  min-width:600px;
}

.home-css-table-footer {
  position: relative;
  top: -165px;
  margin-left: 45px;
  color: #888;
}

/********************************************/
/* END - CSS table for centering search-bar */
/********************************************/

/**********************************/
/* START - stats on splash page   */
/**********************************/
.stat {
  margin-bottom: 5px;
}

.stat-value {
  color: #AAA;
}
/**********************************/
/* END - stats on splash page     */
/**********************************/

.header-icons {
  color:#ccc;
  padding:0;
  margin-right:3px;
}
.header-icons:hover {
  color: white;
  background-color:#555;
}
.content-icon {
  color:#777;
  margin-right:3px;
  vertical-align:top;
}

.btn-transparent-light {
  color: #eee !important;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  &:hover, &:active, &:focus {
    background-color: rgba(0, 0, 0, 0.4) !important;
    color:white !important;
  }
}
.btn-classic {
  color:#abff57 !important;;
  margin-left:4em;
  border-radius: 3px;
  &:hover, &:active, &:focus {
    color:#9aff68 !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
}

.btn-classic {
  color:#abff57 !important;;
  margin-left:4em;
  border-radius: 3px;
  &:hover, &:active, &:focus {
    color:#9aff68 !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
}

.btn-accordion-header {
    @extend .pull-right;
    @extend .btn;
    @extend .btn-xs;
    background: #fcfcfc;
    border-color: #b3b3b3;
    font-size:small;
    padding: 0.25em 0.5em;
    margin-top: -0.1em;
}

.icon-accordion-header {
    @extend .pull-right;
    @extend .btn;
    @extend .btn-xs;
    background: #c0c0c0;
    border-color: #b3b3b3;
    font-size:small;
    padding: 0px;
    padding-left: 2px;
    padding-right: 2px;
    margin-right:5px;
}

.dropdown.open .btn.btn-transparent-light.dropdown-toggle {
  @extend .btn-transparent-light;
  background-color: rgba(0, 0, 0, 0.3);
}

.btn.btn-transparent-light {
  @extend .btn-transparent-light;

  &.dropdown-toggle {
    @extend .btn-transparent-light;
  }
}


body {
  height: 100%;
  min-height: 100%;
  max-height: none !important;
  position: relative !important;
  overflow-y: auto !important;

  header+div {
    padding-left:2em;
    padding-right:2em;
    background-color:#fafafa;
  }
}

footer .row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

footer div > * {
    vertical-align:middle;  // Align children to middle of line
}

footer li {
  color:#bbb;
}

footer div > * {
    vertical-align:middle;  // Align children to middle of line
}

footer a {
  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: white;
    cursor:pointer;
  }
}

footer div.columnMargin {
  margin-left:3em;
}

article {
  padding:20px;
}

a.morelink {
  margin-left: 20px;
  font-weight: 700;
}
/************************/
/*     Explain modal    */
/************************/
.explain {
  em {
    font-weight: bold;
    font-style: normal;
  }
}
.explain-dialog {
  em {
    font-weight: bold;
    color: #666;
    background-color: #FFFFCD;
  }
}

.explain-summary {
  color: $medium-gray;
  font-style: italic;
  font-size:small;
  em {
    font-weight: bold;
    color: #666;
    // background-color: #FFFFCD;
  }
}

/************************/
/* Date Picker PopOver  */
/************************/

.date-picker-popover {
  max-width: 550px;
}

.date-picker-popover .popover-inner {
  width: 550px;
}

/*****************************/
/* END - Date Picker Popover */
/*****************************/

/************************/
/* Date Picker Subset  */
/************************/

.date-picker-subset {
  min-width: 550px;
}

/*****************************/
/* END - Date Picker Subset */
/*****************************/

/************************/
/* Spatial Picker  */
/************************/

.spatial-picker .btn-primary { margin-top: 25px }

.spatial-picker-popover {
  max-width: 800px;
  .popover-inner { width: 540px; }
}

.subsetter .spatial-picker {
  label { display: none; }
  .btn-primary { margin-top: 0px }
}

.home-external-app-link {
  background-color:transparent !important;
  font-size:110%;
  border:none;
  color:#eee !important;
  margin-top:10px !important; 
  margin-left:1em !important; 
  border-radius:3px !important;
  &:hover {
    color:white !important;
    background-color:rgba(0,0,0,0.3) !important;
  }
}

.input-group .form-control:first-child:last-child,
.input-group .popover .form-control:not(:first-child):not(:last-child) {
  border-radius: $input-border-radius;
}

.point-control {
  display:inline-block;
}

.inputReminderLabels {
  font-size:small;
  font-weight:bold;
  color:#888;
  position:absolute;
  top:4.5em;
  left:1em;
}

.inputReminderLabel {
  width:6em;
  margin-left:5px;
}

.mouseClickReminder {
  color: $warning-color;
}

/************************/
/* END - Spatial Picker */
/************************/

/*******************************/
/* START - Splash Page Widgets */
/*******************************/

.introjs-tooltip {
  min-width: 400px;
}

.tour-header {
  background: #eee;
  padding: 10px 10px 10px 20px;
  margin: -10px -10px 10px -10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: bold;
}
.introjs-button {
    display: inline-block !important;
    margin-bottom: 0 !important;
    font-weight: normal !important;
    text-align: center !important;
    vertical-align: middle !important;
    -ms-touch-action: manipulation !important;
    touch-action: manipulation !important;
    cursor: pointer !important;
    background-image: none !important;
    border: 1px solid transparent !important;
    white-space: nowrap !important;
    padding: 5px 10px !important;
    font-size: 14px !important;
    line-height: 1.428571429 !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    text-shadow: none !important;
    text-decoration: none !important;
    background-color:#eee;
    color: $tour-call-to-action-color !important;
}
.introjs-button:hover {
    background-color:#ccc !important;
    box-shadow:none !important;
    color: $tour-call-to-action-color !important;
}
.introjs-nextbutton {
    color: #fff !important;
    background-color: $tour-call-to-action-color !important;
}
.introjs-nextbutton:hover {
    background-color: $tour-call-to-action-hover-color !important;
    color: #fff !important;
}
.introjs-disabled {
    color:#ccc !important;
    background-color: #fff !important;
}
.introjs-disabled:hover {
    color:#ccc !important;
    background-color: #fff !important;
}
.introjs-donebutton {
    color: #fff !important;
    background-color: $tour-call-to-action-color !important;
}
.introjs-donebutton:hover {
    background-color: $tour-call-to-action-hover-color !important;
    color: #fff !important;
}
/*
.introjs-skipbutton {
    color: #2269a6 !important;
}
.introjs-prevbutton {
}
.introjs-prevbutton:hover {
    color:black;
}
*/

.splash-container {
  display: flex;          /* equal height children */
  /*min-width: 1080px;*/
  background-color:$deep-deep-blue;
  color:#bbb;
  margin-left:-2em;
  margin-right:-2em;
  padding-left:2em;
  padding-right:2em;
}

.splash-welcome {
  &.container {
    width:20em;
    height:14em;
    background:linear-gradient(to right, transparent, transparent, #e9f3ff);
    margin-right:1em;
    padding-right:1em;
    border-right:1px solid #eee;
    float:left;
  }
  &.image {
    float:left;
    width:35%;
    margin-top:0.5em;
  }
  &.welcome {
    float:right;
    margin-top:1.5em;
  }
  &.text {
    margin-left:0.2em;
    letter-spacing:-0.01em;
  }
  &.acronym {
    font-size:26pt; 
    text-shadow:3px 3px 5px #9bd;
  }
  &.byline {
    &.text {
      float:left;
      margin-left:0.3em;
      letter-spacing:-0.01em;
      margin-top:0.5em;
    }
    &.links {
      float:right;
      text-align:right;
      margin-top:0.5em;
    }
  }
}

.splash-onboard {
  padding-right:1em;
  letter-spacing:-0.01em;
}

.splash-noshow {
  float:left;
  margin-top:0.45em;
  margin-left:0.5em;
  vertical-align:middle;
}

.splash-widget {
  flex: 1;
  margin: 0 10px 20px;
  padding-bottom: 30px;
  background-color: $dark-blue;
  border-radius:5px;
  a.moreLink {
    display: block;
    position: absolute;
    bottom: 8px;
    right: 20px;
    margin-left: 20px;
  }
}

.splash-widget a:hover {
  color:lightblue;
}

.splash-carousel {
  image-carousel {
    height: 8em !important;
  }
  .carousel-inner > .item {
    height: 10em;
  }
}

image-carousel {
  border: 1px solid $medium-gray;
  border-radius: 3px;
  height: 250px;
  background-color: #000;
}

.carousel-inner > .item {
  height: 220px;
}

.carousel-caption {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 57px;
  background-image: linear-gradient(to top, #0a121b, rgba(10, 18, 27, 0.2));
  left: 0px;
  right:0px;
  bottom:0px;
  padding-left:8px;
  padding-right:8px;
  padding-top: 10px;
  padding-bottom:10px;
  margin: 0 auto;
  color: #fff;
  text-align: left;
  font-size: 12px;
  line-height: 1.4;
  text-overflow: ellipsis;
  a {
    color:#fff;
    text-decoration: none;
  }
}

.carousel-control {
  width: 8%;
  .glyphicon-chevron-left {
    left: 22%;
  }
  .glyphicon-chevron-right {
    right: 22%;
  }
}

.carousel-indicators {
  bottom: -36px;
}

/*****************************/
/* END - Splash Page Widgets */
/*****************************/

/********************/
/* Science PopOver  */
/********************/
.science-popover {
  max-width: 800px;
  color: $text-color;
  font-size: 0.9em;
  left: 10px !important;
  p a {
    font-style: italic;
    text-decoration:underline;
  }

  &.atmos {
    img {
      width: 90%;
    }
    .arrow {
      left: 15% !important;
    }
  }
  &.water {
    .arrow {
      left: 31% !important;
    }
  }
  &.carbon {
    .arrow {
      left: 33% !important;
    }
  }
  &.climate {
    .arrow {
      left: 57% !important;
    }
  }
}

/*************************/
/* END - Science PopOver */
/*************************/

/**********************************/
/* START - Get Data page  */
/**********************************/

.data-access h4 {
  margin-top: 30px;
}

/*******************************/
/* END - Get Data page */
/*******************************/

/***********************************/
/* START - Button and Menu Styles  */
/***********************************/

.dropdown-menu > li > a > i {
  margin-left:-13px;
  margin-right:3px;
}

/*************************************/
/* END - Button and Menu and Styles  */
/*************************************/

/**************************************/
/* START - Accordion component Styles */
/**************************************/

.panel-default > .panel-heading + .panel-collapse > .panel-body { 
  border:1px solid #fafafa;
  background-color:white; 
}

.panel-default > .panel-heading {
  background-color:$light-grey;
}

.accordion-toggle {
  font-weight: bold;
  i {
    width: 15px;
  }
}

.download-dialog {
  .subsetter { margin-top: 5px; margin-left:5px; margin-right:5px; }
  .panel-group { margin-bottom: 0px; }
  .panel-body .subsetter:first-of-type { margin-top: 0px; }
}

/* for use with service dialogs */
@media (min-width: 800px) {
  .modal-lg { 
    width: 80em; 
  }
  .modal-xl { 
    width: 90%;
  }
  .modal-flg { 
    width: 80em; 
    margin: 10% auto;
    vertical-align: middle;
  }
  .modal-fxl { 
    width: 90%;
    margin: 10% auto; 
    vertical-align: middle;
  }
}
.boundaryCB::after {
    content: "\f0ac";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    display: inline-block;
    cursor:pointer;
    color:#9191fb;
    margin-left:0.3em;
}
.boundaryCB:hover::after {
    color: #4040a6;
}
.boundaryCBChecked::after {
    content: "\f00c";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    display: inline-block;
    cursor:pointer;
    color:green;
    margin-left:0.3em;
}

/* 
 * Hide main body content when modal is active
 * in order to reduce unneccessary vert scroll bars
 */
.hideOverflow {
  overflow-y:hidden !important;
}
.showOverflow {
  overflow-y:auto !important;
}

$download-tab-container-color: white;

.download-list-container {
  background-color:$download-tab-container-color; 
  padding: 0.1em 1em 0.5em 1em;
  margin:0
}
.download-results-infiniscroll-container {
  margin: 0.3em 0em 0em -0.9em;
  padding: 10px;
  height: 24.35em;
  overflow: auto;
  /* border: 1px solid lightgray; */
  background-color:white;
}

.download-controls {
  padding: 1em 1.5em 1em 0.2em;
  margin:0em;
  border:none;
  background-color:$download-tab-container-color;
}

.download-link-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: grey;
}

.download-button {
  text-decoration: none;
  border: 1px solid #eee;
  border-radius: 0.3em;
  color:#444;
  background-color: white;
  padding: 0.4em 0.8em;
  &:hover {
    text-decoration:none;
    background-color: #f0f0f0;
  }
}

.download-search-parameters {
  box-shadow:none;
  -webkit-box-shadow:none;
  border:1px solid #eee;
  .panel-heading {
    background-color:$download-tab-container-color;
  }
}

.selectionSummary {
  position: absolute;
  left: 225px;
  i {
    width: 1.28571428571em;
  }
}

.subset-dialog-section {
  margin-bottom: 2em;
}

.subset-dialog {
  padding-right: 20px;
  padding-left: 20px;
}

.subset-help-icon {
  color: $howto-color;
  font-size:0.8em;
  padding:1px 3px 1px 3px;
  border:1px solid white;
  border-radius:5px;
}
.subset-help-icon:hover {
  background-color:#eee;
}

.subset-link {
  display:inline-block;
  vertical-align:middle;
  margin-top:1.2em;
}

.subset {
  select {
    border:1px solid $med-light-grey;
    border-radius:5px;
    padding:3px 5px;
  }
  .explanation {
    color:#777;
  }
}

.file-dialog {
  li.focus {
    background-color:#fcc;
  }
}

.file-dialog-file-table td {
    padding:0px 5px 7px 0px;;
}

.file-dialog-polygon-advice {
  color:$warning-color-light-background;
  font-style:italic;
  .active {
    display:block;
  }
  .inactive {
    display:none;
  }
}

/************************************/
/* END - Accordion component Styles */
/************************************/

/**********/
/* Facets */
/**********/

.facet .close { display: none; }
.modal .facet .close { display: block; }
.facet-panel {
  /* background:linear-gradient(to right, #f0f0f0, #f5f5f5); */
  border-right:1px solid #ccc;
  padding-top:0.15em;
}
.facet-member-selected {
  color:black;
}
.facet-member-unselected {
  color:$dark-gray;
}
.facet-chip-panel {
  background:linear-gradient(to right, #c9c9c9, #e5e5e5);
  overflow:hidden;
}
.facet-chip-selected {
  /*background-color:#629462;*/
  background-color:#5d8ab1;
  font-size:9pt;
}

/****************************************/
/* START - Facet Results - Table Styles */
/****************************************/
.resultsHeader {
    border-bottom:1px solid #ddd;
}

.result-table {
  border-left:1px solid #eee;
}

.refine-by {
  font-size:16pt;
  font-weight:bold;
  color:#eea236;
  margin-top:-0.38em;
  margin-bottom:0.58em;
}

.headerCell {
  background-color: $page-background-color;
}

.headerCell-dataset {
  col-span: 2;
}

.headerCell-tempRes {
  min-width: 7em;
}
.headerCell-version {
  min-width: 7em;
}
.headerCell-spatialRes {
  min-width: 8em;
}
.headerCell-source {
  min-width: 76px;
}
.headerCell-browseImage,
.rowCell-processLevel {
  text-align: center;
}

.headerCell-startDate,
.headerCell-endDate,
.rowCell-startDate,
.rowCell-endDate {
  white-space: nowrap;
}

.rowCell-browseImage {
  display: block;
  min-height: 120px;
}

.rowCell-dataset {
  min-width:2em;
  td.cell {
    vertical-align:top;
  }
}

/**************************************/
/* END - Facet Results - Table Styles */
/**************************************/

/* search results header */
.search-results-stats {
  font-size:12pt;
  color:#555;
  .content-type {
    font-weight:bold;
  }
  .keywords {
    color:$search-call-to-action-color;
    font-weight:bold;
  }
}
/*
.search-results-stats .type {
    font-weight:bold;
}
.search-results-stats .keywords {
    color:$search-call-to-action-color;
    font-weight:bold;
}
*/

/****************************/
/* START - Thumbnail styles */
/****************************/

.thumb-sm {
  width: 75px;
  height: 75px;
}

.thumb-md {
  width: 180px;
  height: auto;
}

.thumb-lg {
  width: 350px;
  height: auto;
}

.thumb-responsive {
  img {
    @extend .img-responsive;
  }

}

.news-reader-thumb-sm {
  width: 60px;
  height: 60px;
}

.hoverHint {
  color: $focusable-element-color;;
}

.cloud-enabled-badge {
  background-color: $search-call-to-action-color;
  color: #fff;
  text-shadow: 2px 2px 3px #555;
  font-weight: bold;
  text-align: center;
  font-size: larger;
  margin-top: 1em;
  border-radius: 0.8em;
  border:2px solid orange;
}
.cloud-enabled-badge:hover {
  cursor:default;
  background-color: $search-call-to-action-color-active;
}

.cloud-enabled-popover {
  color:#555; 
  text-align:left; 
  width:50em;
  div.left-column {
    float:left; 
    font-weight:bold;
    margin:0.1em 1em 0em 0.9em; 
  }
  .row {
    margin-bottom:0.35em;
  }
  .spacer {
    margin:0;
    padding:0;
  }
}

.thumbContainer {
  display:block;
  cursor: pointer;
  text-align:center;
  border:1px solid #eee;
  box-shadow:3px 3px 5px #ccc;
}

.thumbContainer:hover {
  &:hover:not(.thumb-manual),
  &.thumb-manual.thumb-expand {
    text-decoration: none;
    padding: 0px;
    /*border: 2px solid #ccc;*/
    border-radius: 3px;
    .thumbnail {
      border-color: transparent;
    }
  }
  box-shadow:3px 3px 5px #999;
}
.thumbnail {
  border:none;
  background-color:transparent;
}

div.expandBrowseThumbnail {
  max-width:80em;
  background:white;
  text-align:center;
  img {
    max-width:95%;
    padding:5px;
  }
}

.summaryThumb {
  margin:0;
  margin-right:15px;
  padding:0;
  width: 180px;
  a.btn {
    width: 160px;
    color: $focusable-element-color;
    margin-top: -10px;
  }
}

div.fullScreenPop {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:2;
  padding:1em;
  background-color:white;
  width:100%;
  height:100%;
  text-align:center;
  cursor:default;
  overflow-y: auto;
  img {
    max-width:98%;
  }
}
.popImageButton {
  border:none;
  padding:1em;
  background-color:white;
}
.popImageButton:hover {
  border:none;
}
.figureCounter {
  min-width:3.2em;
}
.figureButton {
  font-size:x-large !important;
  border:none;
  cursor:pointer;
  background-color:transparent;
  color:#777;
  padding:0;
}
.figureButton:hover {
  color:black;
}
.figureButton:active, .figureButton:focus {
  border:none !important;
}
.popPrevButton {
  margin-left:0.5em;
}
.popCloseButton {
  position:absolute;
  top:0.5em;
  right:1em;
}

.browseCaption {
  font-style:italic !important;
  white-space:normal !important;
  text-align:center !important;
  width:96%;
  margin:0 auto;
}
div.browseDescription {
  margin-top:1.5em !important;
}

div.description { background-color:#dfdfdf; }

figure.snapshot {
  margin: 1.5em 40px;
}

figure.snapshot img {
  border-radius: 0;
  display: block;
}

figure.snapshot figcaption {
  margin-top:10px;
  margin-left:10px;
  color:#858585;
  font-style:italic;
}

/****************************/
/*  END - Thumbnail styles  */
/****************************/

/**********************************************/
/* START - Sort Icon styles for table columns */
/**********************************************/
/* table {
  background-color: #FFF;
} */

th a.no-link  {
  cursor: pointer;

  i {
    width: 1em;

    &.fa-sort-asc {
      position: relative;
      top: 2.5px;
    }

    &.fa-sort-desc {
      position: relative;
      top: -3.5px;
    }
  }
}

/********************************************/
/* END - Sort Icon styles for table columns */
/********************************************/

/*************************************/
/* START - Discipline popover styles */
/*************************************/
.disciplines {
  font-size: 0.9em;
  color: $medium-gray !important;
  margin-top: 7px;
}

.disciplines i {      /* fontawesome expansion icon */
  font-size: 1.2em;
  color: $medium-gray;
}

.disciplines i:hover,
.disciplinesPopover { color: $text-color; }

/***********************************/
/* END - Discipline popover styles */
/***********************************/

/***********************************/
/* START - Data Set animated Icons */
/***********************************/

.pop {
  transition: all ease 150ms;
}

button.pop:hover > * {
  transform: scale(1.6);
}

/**********************************/
/* END - Data Set animated Icons  */
/**********************************/

/********************************/
/* START - Content Type Icons   */
/********************************/

/* !important added to override tophat2 .fa and other rules */
.th-wrapper {
  header#earthdata-tophat2 {
    padding-left:0.7em;
    background:#0d1c2f !important;
    border-bottom: 1px solid #555;
    .th-link-container {
      margin-left:10em;
      padding-top:2px !important;
    }
  }
}

@mixin iconBadge($name, $diameter, $font-size) {
  .icon-badge-#{$name} {
    width: $diameter;
    height: $diameter;
    line-height: $diameter + 2px !important;
    font-size: $font-size !important;
  }
  .icon-badge-title-#{$name} {
    width: calc(100% - #{$diameter + 10px});
  }
}

@include iconBadge("sm", 36px, 22px);
@include iconBadge("md", 44px, 24px);
@include iconBadge("lg", 64px, 36px);

.icon-badge {
  border-radius: 50%;
  text-align: center;
  background-color: gray;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.icon-badge {
  color: #F2F2F2;
}

.icon-badge-title {
  margin-left: 10px;
}

.howto-bg-color {
  background-color: $howto-color;
}
.alerts-fg-color {
  color:$alerts-color;
}
.alerts-fg-color:hover {
  color:white;
}
.alerts-bg-color {
  color: #eee;
  background-color: $alerts-color;
}
.alerts-color {
  color: $alerts-color;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size:1.2em;
}
.alerts-color:hover {
  text-shadow: -1px 0 red, 0 1px red, 1px 0 red, 0 -1px red;
}
.news-bg-color {
  background-color: $news-color;
}
.documents-bg-color {
  background-color: $documents-color;
}
.publications-bg-color {
  background-color: $publications-color;
}
.glossary-bg-color {
  background-color: $glossary-color;
}
.faqs-bg-color {
  background-color: $faqs-color;
}
.manuals-bg-color {
  background-color: $manuals-color;
}
.images-bg-color {
  background-color: $images-color;
}
.tools-bg-color {
  background-color: $tools-color;
}
.mission-project-bg-color {
  background-color: $mission-project-color;
}
.fa-warning:before {
  bottom: 1px;
  position: relative;
  left: 1px;
}

/*******************************/
/* END - Content Type Icons    */
/*******************************/

/*************************/
/* START - Scroll to Top */
/*************************/

.cd-top {
  height: 40px;
  width: 40px;
  padding: 8px 10px 10px;
  border-radius:10px;
  position: fixed;
  bottom: 40px;
  right: 15px;
  color: white;
  background-color: transparent;
  visibility: hidden;
  transition: background-color 300ms 0s, visibility 0s 300ms;
  z-index: $zTop;

  &:hover,
  &:focus,
  &:active {
    color: white;
  }
}

.cd-top.cd-is-visible,
.cd-top.cd-fade-out,
.no-touch .cd-top:hover {
  transition: background-color 300ms 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.65);
}

.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  background-color: rgba(0, 0, 0, 0.5);
}

/*************************/
/* END - Scroll to Top   */
/*************************/

.status-list {
  position: fixed;
  right: 15px;
  bottom: 0;
  width: 262px;
  z-index: 1035;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0px;
  border-top: 1px solid #777;
  border-left: 1px solid #777;
  border-right: 1px solid #555;

  .panel-heading {
    .panel-title {
      line-height: inherit;
      display: inline;

      i { margin-top: 3px; }
    }
    + div {
      background-color: white;
    }
  }

  .status-body {
    height: 267px;

    .list-group {
      height: 100%;
      overflow: auto;

      .list-group-item {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        i {
          width: 32px;
          height: 32px;
          padding-top: 9px;
        }

        h4 {
          width: calc(100% - 41px);

          small { font-weight: 500; }
        }
      }
    }
  }
}

.status-body {
  i {
    width: 32px;
    height: 32px;
    padding-top: 9px;
  }

  h4 {
    width: calc(100% - 41px);

    small { font-weight: 500; }
  }
}

// Merge
.primary-glow {
  box-shadow: 0px 0px 10px 2px $focusable-element-color;
}

.status-list-zindex {
  z-index:1039;
}

.modal {
  opacity: 0;
}

.form-group {
   margin-bottom: 5px;

  .help-block {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &:not(.has-error) .help-block:after { content: "\00a0"; }
}

.text-wrap { white-space: normal; }

.titelize {
  text-transform: capitalize;
}

.capitalize {
  display: inline-block;
}

.capitalize:first-letter {
  text-transform: capitalize;
}

.glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.glyphicon-chevron-left:before {
    content: "\f053";
  }
  &.glyphicon-chevron-right:before {
    content: "\f054";
  }
}

.dropdown-menu.dropdown-menu-right-align {
  left: auto;
  right: 0px;
}

.text-default { color: $text-color; }

.circle { border-radius: 50%; }

.bg-royal-purple {
  color: white;
  background-color: rgb(103,50,133);
}

.bg-teal {
  color:white;
  background-color: rgb(0, 128, 128);
}

// cause I don't find bootstrap tabs pretty enough, so material design tabs
.tabs {
  display: inline-block;
  //box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),0 1px 1px 0 rgba(0, 0, 0, 0.14),0 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-top:1em;
  margin-bottom:1em;
  .btn {
    font-weight: 500;
    border-radius: 3px !important;
    transition: all 300ms ease-in-out;
    margin-bottom: 4px;
    &.active-tab {
      border-bottom: 2px solid $brand-primary !important;
    }
    &.active-splash-tab {
      background-color:rgb(51,122,183) !important;
    }
    & + .btn {
      border-radius: 0px 3px 3px 0px !important;
      border-left: none !important;
    }
  }
}

@media print {
  /* Remove background colors and images, use black ink */
  * {
    background: transparent !important;
    color: black !important;
  }

  body {
    width: auto !important;
    font-size:12pt;
  }

  /* Limit width of images to their containing elements */
  img { max-width: 100% !important; }
}

.search-aid {
  padding:0.4em 0.5em 0.2em 0.5em;
  background-color:#e6ebf5;
  margin-top:1.45em;
  i {
    color: $error-color;
    background-color:white;
    vertical-align:top;
    margin-top:0.2em;
  }
  div {
    margin-top:0.07em;
  }
  ol {
    display:block;
    line-height:1.7em;
  }
  .question {
   font-size:larger;
  }
  .suggestion {
    display:inline-block;
  }
  .suggestions {
     padding:0.1em 0.5em 0.3em 2em;
  }
  .close-aid {
    float:right;
    margin-top:-2em;
    margin-right:-0.3em;
    transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  }
  .close-aid-icon {
    color:#999;
    background-color:transparent;
    &:hover {
      color:#777;
    }
  }
}

.dataset-summary {
  width: 100%;
  border: none;
}

.dataset-services {
  white-space:nowrap;
}
.dataset-services-action {
  background-color:transparent;
}

.history-container {
  width: auto;
  overflow: hidden;

  &+.history-container { display: none; }
}

$row-even: #fafafa;
$row-odd: #FFF;

/****************************/
/* Information View Styles  */
/****************************/

.content-list {
  margin-left:0em;
}

.content-list-container {
  display:flex; 
  flex-flow:column; 
  margin-top:1em;
}

.content-list-header {
  display:inline-flex; 
  flex-flow:row;
}

.page-utilities {
  display:inline-flex; 
  margin-left:auto;
}

.infoView {
  li {
    margin-bottom:0px;
    box-shadow:none;
    border:none;
    background-color:transparent;
    padding-bottom:0px;
  }
  div[ng-form] {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 20px 10px;
    .childForm {
	  border: 0;
	  border-radius: 0;
	  padding: 0;
  	}
  }
  input {
    line-height: normal;
  }
  input[name=title] {
    width:85%;
  }
  textarea {
  /*  resize: none; */
    width: 100% !important;
  /*  height: 70px !important; */
  }
  select {
    width: auto;
  }
  .row {
    padding-bottom:5px;
  }
  .label {
    margin-left: 3px;
  }
  .caption {
    font-weight:bold !important;
  }
}

.userContentView {
  padding-left:8em !important;
  padding-right:8em !important;
  padding-top:5em !important;
  p { font-size:16px; }
}

.pubsSection {
  margin-top: 1em;
}

.pubsOnlineLink {
  margin-top: -0.6em;
  margin-bottom: 1em;
}

.pubsList {
  /*font-family:sans-serif;*/ 
  /*font-size:13pt;*/ 
  color:#585858;
}

.mission-list-image {
  display: block; 
  height: 220px !important; 
  overflow: hidden; 
  border:1px solid #eee;
  div {
    background-color:#def;
    height:15em;
    font-size:24pt;
    padding:1.5em;
    text-align:center;
  }
}

.mission-list-abstract {
  height:7em; 
  padding-top:0.5em; 
  font-size:12pt; 
  font-family:serif;
}

.mission {
  flex-flow:row;
  .related-tile {
    /* float:left; */
    width:21em;
    height:30em;
    border: 1px solid #ddd;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.3);
    margin-right:1.5em;
    margin-bottom:1em;
    a.related-anchor-tile {
      display:block; 
      height: 220px !important; 
      overflow: hidden; 
      margin:10px 10px 0px 10px; 
      border:1px solid #eee;
    }
    &.no-image {
      background-color:#def;
      height:15em;
      font-size:24pt;
      padding:1.5em;
      text-align:center;
    }
    &.abstract {
      height:7em; 
      padding-top:0.5em; 
      font-size:12pt; 
      font-family:serif;
    }
  }
  img.overview-image {
    width:18em; 
    float:right; 
    margin:0em 0em 0.5em 2em;
  }
  img.related-image {
    display: block;
    border:1px solid #eee;
    width: 300px;
    border-radius: 3px;
    &.scale-to-fill {
      transform:scale(2.45);
      -ms-transform:scale(2.45); /* IE 9 */
      -moz-transform:scale(2.45); /* Firefox */
      -webkit-transform:scale(2.45); /* Safari and Chrome */
      -o-transform:scale(2.45); /* Opera */
    }
  }
  .related-title {
    margin-bottom: 1.5em; 
    min-height: 4.5em;
  }
  .related-caption {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    font-size: 17px;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding: 8px;
    margin-top: 0em;
    border: 1px solid #ddd;
    color: #888;
    background-color: #eee;
    height: 3.4em;
    line-height: 1.4em;
    padding-top: 0.25em;
    a {
      text-decoration:none;
    }
    &:hover {
      color:#555;
      background-color:#def;
      text-decoration:none;
    }
  }
  .related-aside {
    background-color: $page-background-color;
    min-width: 12em;
    border-right: 1px solid #ccc;
    padding: 1.75em 2em 1em 0em;
    max-width: 25%;
  }
  .data-preservation-no-content {
    color:#996633;
    font-style:italic;
    font-size:larger;
  }
}

.required:before {
    content: "*";
    padding-right: 4px;
    color: #a94442;
}

.btn-content-group .btn {
  margin: 5px 0px;
  color: $focusable-element-color;
}

.btn-content-group .copy-link-button-tooltip .tooltip-inner {
  max-width: none;
  border-color: red;
  border: solid;
  border-width: thin;
  box-shadow: 5px 5px 5px grey;
}

/*
  Used for dynamic textarea elements that need to hold text and remain
  invisible.
  The likelihood is the element won't even render, not even in flash,
  so some of these are just precautions.
*/
.invisible {
  /* Place in top-left corner of screen regardless of scroll position. */
  position: 'fixed';
  top: 0;
  left: 0;

  /* Ensure it has a small width and height. Setting to 1px / 1em
     doesn't work as this gives a negative w/h on some browsers. */
  width: '2em';
  height: '2em';

  /* We don't need padding, reducing the size if it does flash render. */
  padding: 0;

  /* Clean up any borders. */
  border: 'none';
  outline: 'none';
  box-shadow: 'none';

  /*  Avoid flash of white box if rendered for any reason. */
  background: 'transparent';
}

.input-date {
  width:150px;
}
.list-accordion {
  .panel {
   box-shadow:none;
   border:none;
  outline:none;
  }
  .panel-heading {
    background-color:transparent !important;
  }
  .panel-body {
    background-color:transparent !important;
    border:none;
    padding:0;
  }
  .panel-group {
    margin-bottom:0px !important;
  }
  .header {
    margin-left:-0.7em; 
    font-size:14px; 
    color:#555;
    i {
      color: $focusable-element-color;
    }
    i:hover {
      color: $deep-blue;
    }
  }
}
.tag {
  display: inline-block;
  padding: 0.3em 1.1em;
  margin-right: 1em;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
  border: 1px solid $tag-border-color;
  border-radius: 0.5em;
  background-color: $tag-color;
  color: $focusable-element-color;
  font-weight:bold;
  font-size:smaller;
  cursor: pointer;
  letter-spacing: 0.06em;
}
.tag:hover {
  background-color: $tag-color-hover;
  color:white;
}
.history {
  height: 100%;
  .panel-body {
    height: calc(100% - 31px);
    overflow-y: auto;
    border: 1px solid;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .revision-history {
      .list-group-item {
        border: none;
        border-bottom: 1px solid $dropdown-border;
        border-top: 1px solid $dropdown-border;
        border-radius: 0px;
      }
      .active {
        background-color: #d9edf7 !important;
        color: #555 !important;
        div:first-child {
          font-weight: bold;
        }
      }
      .bg-success {
        background-color: #dff0d8 !important;
      }
    }
  }

}
/* allows elements to animate */
.block:not(.collapse) {
  display: block;
}

section {
  margin: 15px 0px;
}

h4.section {
  font-weight: bold;
}
div.section-body {
  padding-bottom: 1.5em;
}

h4.description {
  font-style:italic;
  font-weight:normal;
  color:#555;
  padding-top:0.5em;
  padding-bottom:0.5em;
}

hr.bigrule {
  margin-top:3em;
  margin-bottom:2em;
  margin-left:0px;
  margin-right:0px;
  border-top:0.1em solid #ccc;
}

.content-figure {
  border:1px solid transparent;
  box-shadow:2px 2px 7px #ccc !important;
  background-color:transparent !important;
  margin-bottom:0.5em;
}
.content-figure:hover {
  box-shadow:2px 2px 7px #aaa !important;
}
.content-figure-image {
  border:1px solid transparent; 
  background-color:white;
}
.content-spacer {
  margin-bottom:1em;
}

.crop-label {
  font-size: 1.25em;
  padding-left:15px;
  input {
    margin-right: 15px;
    transform : scale(1.5);
  }
}

/**********************************/
/* END - Information View Styles  */
/**********************************/

/*******************************************/
/* START - Gallery and List Layout Styles  */
/*******************************************/

.grid {
  div[ng-repeat] {
    display: inline-block;
    margin: 20px;
    width: 340px;
    height: 400px;
    margin-right: 10px;
    overflow: hidden;
  }
  h4 {
    font-size: 17px;
    white-space: nowrap;
    width: 18.25em;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding: 8px;
    margin-top: 0em;
    margin-left: 0em;
    border:1px solid #ddd;
    color:#888;
    background-color:#eee;
    &:hover {
      color:#555;
      background-color:#def;
    }
  }
  .caption {
  }
  a {
    text-decoration: none;
  }
  img {
    display: block;
    /*width: 300px;*/
    border-radius: 3px;
    &.scale-to-fill {
      transform:scale(1.2);
      -ms-transform:scale(1.2); /* IE 9 */
      -moz-transform:scale(1.2); /* Firefox */
      -webkit-transform:scale(1.2); /* Safari and Chrome */
      -o-transform:scale(1.2); /* Opera */
    }
  }
  .tag-block {
    height: 55px;
    padding: 5px 9px 0px;
    overflow-y: auto;
  }
  .list {
    display: none;
  }

}

.list {
  .grid {
    display: none;
  }
}

/*****************************************/
/* END - Gallery and List Layout Styles  */
/*****************************************/


/********************************/
/* Dataset Landing Page Styles  */
/********************************/

article.dsl {
  min-width: 820px;
}

.dsl {
  h2 {
    margin: 0px 10px 30px 10px;
    font-size: 20px;
    max-width: 85%;
  }
  .summaryContainer {
    display: inline-block;
    height: auto;
    width: calc(100% - 245px);
    min-height: 225px;
  }
  .summary {
    height: auto;
    overflow: hidden;
    position: relative;
    p:last-child {
      display: inline;
    }
  }
  a.moreLink {
    margin-left: 20px;
  }
  .thumbContainer {
    margin: 0 25px 15px 0;
    cursor: zoom-in;
  }
  #dsl-tabs {
    margin-top: 30px;
    padding-left:50px;
  }
  .tab-pane {
    padding:0.85em;
  }
  #data-access-panel {
    display: inline-block;
    width: 180px;
    padding: 10px 0;
    margin: 0 50px 15px 15px;
    /* background-color:#eee; */
    border-top: 1px #bbb solid;
    /* border-radius: 3px; */
    text-align: center;
    h4 {
      text-align: center;
      margin-bottom: 8px;
    }
    .btn {
      width: 90%;
    }
  }
  .btn .caret {
    margin-left: 3px;
  }

  //.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  //  color:#5f5f5f;
  //  font-weight:bold;
  //  font-size:105%;
  //}

}

/**************************************/
/* END - Dataset Landing Page Styles  */
/**************************************/

/*********************************/
/* START - Multi-line Truncation */
/*********************************/

/* styles for '...' */
.trunc-text-block {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (6) */
  max-height: 6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;

  padding-right: 1em;
}
.trunc-text-block:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
.trunc-text-block:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  width: 1em;
  /* set width and height */
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

/*******************************/
/* END - Multi-line Truncation */
/*******************************/


.summaryContainer {
  display: inline-block;
  height: auto;
  width: calc(100% - 245px);
  min-height: 225px;
}
.summary {
  display: inline-block;
  height: auto;
  overflow: hidden;
  position: relative;
  overflow: hidden;
}
a.moreLink {
  margin-left: 20px;
}
.thumbnail {
  margin-bottom: 3px;
/*    padding:2px;  */
  transition: none;
}

.leaflet-container {
  background: #fff;
  border: 1px solid #ddd;

  .leaflet-draw-tooltip {
    &:not(.leaflet-draw-tooltip-single) { margin-top: -12px }
    .leaflet-draw-tooltip-subtext, br { display: none; }
  }

  .leaflet-draw-toolbar a {
    background-image: none;
    .leaflet-retina { background-image: none; }
  }

  .leaflet-draw-draw-rectangle {
    @extend .#{$fa-css-prefix};
    @extend .#{$fa-css-prefix}-lg;
    &:before {
      @extend .#{$fa-css-prefix}-pencil-square-o:before;
      margin-left: 2px;
    }
    &:hover, &:active, &:focus { line-height: 28px; }

    line-height: 28px;
    display:block;
  }
  .leaflet-draw-draw-circle {
    @extend .#{$fa-css-prefix};
    @extend .#{$fa-css-prefix}-lg;
    &:before {
      @extend .#{$fa-css-prefix}-dot-circle-o:before;
      margin-left: 2px;
    }
    &:hover, &:active, &:focus { line-height: 28px; }

    line-height: 28px;
    display:block;
  }
  .leaflet-draw-draw-marker {
    @extend .#{$fa-css-prefix};
    &:before {
      @extend .#{$fa-css-prefix}-circle:before;
      margin-left: 2px;
    }
    &:hover, &:active, &:focus { line-height: 28px; }

    line-height: 28px;
  }
}

.promote-layer {
  transform: translateZ(0);
}

.cursor-pointer {
  cursor: pointer;
}

.mega-menu {
  &.dropdown-menu {
    color: $text-color;
    .categories.list-group {
      border-right: 1px rgba(217, 217, 217, 1) solid;
      min-width: 158px;
      margin-right: -1px;
      .list-group-item {
        border-radius: 0;
      }
    }

    .sub-categories {
      border-left: 1px rgba(217, 217, 217, 1) solid;
      max-width: calc(100% - 158px);
      min-width: 185px;
    }
  }
}

.mega-menu-div {
  padding-left:1.5em;
}


/* Handle overflow of long tags */
.ui-select-placeholder, .ui-select-match-item, .ui-select-choices-row-inner {
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

html-editor, file {
  &.form-control {
    height: 100%;
    line-height: 0;
    padding: 0px;
    border: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

html-editor.form-control {
  min-height: 304px;
  display: block;
}
file.form-control {
  display: inline;
}
input.form-control {
  height:auto;
}

.has-error, .has-success {
  html-editor, file {
    &.form-control {
      display: inline;
      line-height: 0;
      padding: 0px;
      border: none;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
}
.modal-full {
  width: 99%;
}
.inline-header {
  display:inline;
}
.search-header {
  margin:1em 1em 0em 1em;
}

.contact-table {
  border:none;
  width:45em;
}
.contact-row-label {
  vertical-align:top;
  text-align:right;
  font-weight:bold;
  width:15em;
  display:inline-block;
  padding:5px;
}
.contact-row-content {
  vertical-align:top;
  text-align:left;
  width:30em;
  display:inline-block;
  padding:5px;
}
.contact-indent {
  margin-left:0.5em;
}

@keyframes ng-marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

.ng-marquee {
  transition: transform 300ms ease-in-out;
  .ticker {
    a:not(:last-of-type) { border-right: 1px solid $alerts-color; }
    &.loop {
      animation: ng-marquee linear infinite;
      span a:first-of-type { border-left: 3px solid $alerts-color; }
      &:hover { animation-play-state: paused; }
    }
    white-space: nowrap;
  }
  &.ng-marquee-closed {
    transform: translateX(calc(-100% + 29px));
    display:none;
  }
}
/* Handle long labels, e.g. links */
/* Taken from https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
.word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.list-divider+.list-divider {
  border-top: 1px #ccc solid;

}

.initMessage {
  position:absolute;
  top:8em;
  left:2em;
  width:95%;
  height:4em;
  text-align:center;
  vertical-align:middle;
  background-color:#555;
  color:#9af951;
}

.headerCaret {
  color:#ddd !important;
  font-size:1.1em !important;
  vertical-align:bottom;
  margin-bottom:1px;
  margin-left:0.5em;
}

div.datalist-container {
  position:absolute;
  top:-3em;
  right:1.5em;
  min-width:47em;
}
div.datalist-panel {
  box-shadow:10px 10px 15px #999;
  background-color:white;
}
div.datalist-short {
  height:2em;
}
img.datalist-browse {
  /* must match the image width */
  margin-left: calc(50% - 2.7em);
}
fieldset.datalist-fieldset {
  border: 1px solid #ddd;
  margin-top: 5px;
  box-shadow:5px 5px 5px #bbb;
  background-color:white;
  white-space:nowrap;
  overflow-x:auto;
  overflow-y:hidden;
}
.datalist-legend {
  width:95%;
  border: none;
  color: #777;
  font-size:1em;
  margin-left:0.5em;
  font-weight:bold;
  padding:0.5em 0em 0em 1em;
}
div.datalist-tile {
  display:inline-block;
  vertical-align:top;
  text-align:center;
  padding:1.5em;
}
strong {
  z-index:0;
}
div.datalist-browse {
  border:none;
}
a.datalist-label {
  display: block;
}

.datalist-call-to-action {
  color:$refine-call-to-action-color;
  font-weight:bold;
}

/* For dataset search results services table */
.not-striped {
  background:transparent;
}

.datalist-widget {
  float:right;
  cursor:pointer;
  border-radius:5px;
  box-shadow:3px 3px 6px #ddd;
  padding:3px 10px;
  margin-top:-4px;
}
.datalist-widget:hover {
  color:black;
}
.datalist-minified {
  border: none;
  color: #777;
  font-size:1em;
  margin-left:0.5em;
  font-weight:bold;
  padding-left:10px;
  margin-top: 5px;
}
.datalist-minified:hover {
  color:black;
  cursor:pointer;
}
.predictionStats {
  font-size: 0.85em;
  margin-top: 0.8em;
}

.emergencyBanner {
  color:$alerts-color;
  background-color:rgba(0,0,0,0.5);
  font-weight:bold;
  font-size:1.2em;
  border:none;
  padding:0;
  &.bannerClose {
    font-size:1em;
    margin:0;
    padding-left:0.1em 0 0 0.75em;
  }
}
.emergencyBanner:hover {
  color:white;
}

.notebook-icon {
  margin-left:0.8em;
  width:1.7em;
  height:1.5em;
  opacity:0.7;
}
.notebook-icon:hover {
  filter:brightness(140%);
  opacity:1;
}
.map-readout {
  color:#555;
}

.search-options-panel-close {
  display:none;
}
.search-options-panel-open {
  display:block;
}

.popover-anchor {
  text-decoration: underline dotted $medium-gray;
}

.subset-option-label {
  font-weight:normal;
}

.accordion-toggle-button {
  border:1px solid #ddd;;
  background:transparent;
}
.accordion-toggle-button:hover {
  background:#eee;;
}
.accordion-toggle-button-open {
  border-bottom:none;
  background-color:#eee;
}

.accordion-content-button {
  background-color:#eee;
  color:#333;
  border:1px solid #ddd;
  margin-top:0.5em;
  margin-bottom:0.5em;
}
.accordion-content-button:hover {
  background-color:#d9d9d9;
}

.accordion-content-note {
  display:inline-block;
  margin-bottom:0.5em;
}

.dialogWindow {
  margin-top: 10em;
}

.modal-body-margin {
  margin-left:20px;
}

.zero-left-margin {
  margin-left:0;
}
.modal-left-margin {
  margin-left:1em;
}
.modal-right-margin {
  margin-right:1em;
}

img.mirador-guide {
  max-width:75%;
  min-width:75%;
  box-shadow:5px 5px 15px #bbb;
  margin-bottom:1em;
  margin-top:0.5em;
  border:10px solid white;
}

h3.mirador-guide {
  margin-top:1em;
}
h4.mirador-guide {
  color:#d4a96a;
  font-style:italic;
  font-size:x-large;
}
    

.toc {
  margin-bottom:0px;
  list-style-type: none;
}

/* START - UUI-STATIC site styles */
/* The styles below are used by the UUI static site. They hide specific template and content
/* elements that are non-functional on the static version of the site. */

/* Hide elements in the site header  */
.uui-static sort-drop-down-list {
  visibility: hidden;
}
.uui-static #utils {
  visibility: hidden;
}
.uui-static .search-bar-small {
  margin-left: 20%;
  width: 200px;
}

/* Style for warning banner  */
.uui-static .static-warning {
  background-color: rgba(255, 0, 0, 0.5);
  border: 1px solid #999;
  padding: 5px 10px;
  margin: 10px auto;
  width: 80%;
}

/* Only show facet label in search criteria for the static site */
.uui-static .browseby-title {
  visibility: visible;
}
.browseby-title {
  visibility: hidden;
  display: none;
}

/* Chips - disable hover effects */
.uui-static .label-warning[href]:hover {
  cursor: auto !important;
  background-color: $search-call-to-action-color;
}

/* Tags - diasable hover effects  */
.uui-static .tag:hover {
  cursor: auto;
  border: 1px solid #ccc;
  background-color: #eee;
}

/* Gallery - Hide Image layout control */
.uui-static div[ng-if=defaultLayout]{
  display: none;
}

/* Remove Thumbnails hover effects  */
.uui-static div.thumbContainer .btn-default:hover {
  cursor: auto;
  background-color: #fafafa;
  border: 1px solid #ccc;
}

/* Dataset results - hide non-functional elements */
.uui-static .dataset-services-action {
  display: none;
}
.uui-static .facet-panel {
  display: none;
}

/* Datalist products - hide colapsing widget */
.uui-static .datalist-legend {
  display: none;
}
.uui-static .datalist-tile {
  margin-top: 6px;
}

/* Dataset results - disable thumbnails hover effects  */
.uui-static div[ui-view=results-content] div.thumbContainer {
  cursor: auto;
  border: 1px solid #ddd;
}
.uui-static div[ui-view=results-content] div.thumbContainer:hover {
  cursor: auto;
  border: 1px solid #ddd;
}
.uui-static div[ui-view=results-content] div.thumbContainer .thumbnail {
  border-color: transparent;
}
.uui-static div[ui-view=results-content] div.thumbContainer:hover:hover:not(.thumb-manual) .thumbnail {
  border-color: transparent;
}
/* Datasets Reults - Hide caret button for disciplines */
.uui-static span.disciplines button {
  display: none;
}

/* DSL - Expand Data Access Sub-menus  */
.uui-static #data-access-panel ul.dropdown-menu {
	display: block;
	position: relative;
}
/* DSL - Hide inactive tabs  */
.uui-static ul.nav-tabs li:not(.active) {
  visibility: hidden;
}
/* DSL - Show tab contents - unhide them */
.uui-static .tab-content>.tab-pane {
    display: block;
}
/* Bump up DSL tab fonts  */
.uui-static .nav-tabs>li.active>a {
    font-size: 1.2em;
    font-weight: bold;
}
/* DSL page - disable thumbnails hover effects  */
.uui-static .dsl .thumbContainer {
	cursor: auto;
}
.uui-static .dsl .thumbContainer:hover {
  border:2px solid white !important;
}
.uui-static .hoverHint {
  display: none;
}
/* END - UUI-STATIC site styles */
.introjs-fixParent {
  z-index: 9999999 !important;
}

.intro-modal {
  padding-top: 15%;
}

.introjs-hint-dot {
  background-color: green;
}

.data-file-access-tabs {
  a {
    font-weight: bold;
    .active {
      font-size: 1.3em;
    }
  }
  li {
    font-weight:normal;
  }
  .data-file-link-lists-header {
    padding:0.5em;
    background-color:lightgreen;
  }
  .data-file-link-lists-content {
    font-weight:normal !important;
  }
}

/* Timeline styles - modified from react-yearly-calendar */
table.timeline{
  // https://stackoverflow.com/questions/16385578/
  -webkit-transform-origin:left top;
  -moz-transform-origin:left top;
  transform-origin: left top;
  border-collapse: separate;
  border:1px solid #ddd;
  //transform:scale(0.5, 0.5);
}
table.timeline thead{
  // margin-bottom:3px;
  // border-bottom:2px solid #fff;
  font-weight: 500;
  font-size:.8em
}
table.timeline thead th{
  padding:10px 3px;
  text-align:center;
  background-color: rgb(228, 228, 228);
  border:1px solid hsla(0,0%,73%,.13);
}
table.timeline thead th.bolder{
  font-weight:700
}
table.timeline tbody{
  font-size:.8em
}

table.timeline td{
  text-align:center;
  padding:8px;
  cursor:pointer;
  border:1px solid hsla(0,0%,73%,.13);
  min-width:15px
}
table.timeline tr:last-child td{
  border-bottom:none
}
table.timeline td.month-name {
  font-weight:700;
  text-align:left;
  cursor:default;
  border-left:none;
  background-color: rgb(228, 228, 228);
}
table.timeline td.next-month,table.timeline td.prev-month{
  color:transparent;
  cursor:default;
  pointer-events:none
}
table.timeline td.week-separator{
  pointer-events:none;
  padding:0;
  width:8px;
  min-width:0
}

// Color schema
table.timeline td.empty{
  color: rgb(170, 170, 170);
  background-color:rgba(255, 255, 255, 0.32)
}
table.timeline td.today{
  background-color:rgba(159,220,249,.32)
}
table.timeline td.available{
  background-color:rgba(141, 207, 105, 0.32)
}
table.timeline td.available-today{
  background-color:rgba(61, 167, 4, 0.32)
}
// https://stackoverflow.com/questions/30376886/
table.timeline td.datagap{
  background-color:rgba(246,151,60,.32);
  background: linear-gradient(to bottom right,  rgba(246,151,60,.32) calc(50% - 1px), rgba(170, 170, 170, 0.9), rgba(246,151,60,.32) calc(50% + 1px))
}
table.timeline td.datagap-today{
  background-color:rgba(187, 93, 4, 0.32);
  background: linear-gradient(to bottom right,  rgba(187, 93, 4, 0.32) calc(50% - 1px), rgba(170, 170, 170, 0.9), rgba(187, 93, 4, 0.32) calc(50% + 1px))
}

table.timeline td.days:hover {
  background-color: $focusable-element-color;
}

table.timeline td.bolder{
  font-weight:700
}
// table.timeline td.datagap.range,table.timeline td.datagap.selected{
//   background-color:#9fdcf9;
//   font-weight:700
// }
div.timeline-container{
  width: 100%;
  margin:5px;
}
div.timeline-controls{
  margin:5px auto;
  display:table;
  font-size:25px;
  line-height:35px
}
div.timeline-controls div{
  display:inline
}
div.timeline-controls .current-year{
  margin:0 30px
}
div.timeline-controls .control{
  font-weight:bolder;
  color:#323232;
  font-size:.8em;
  cursor:pointer
}
div.timeline-controls .today{
  position:absolute;
  right:15px;
  line-height:35px;
  font-size:.6em;
  text-transform:uppercase
}
div#timeline {
  position:relative;
  border-radius:5px;
  border:1px solid #5a5a5a;
  background-color:#fff;
  overflow:hidden;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}
span.timeline-control-label {
  margin-left: 10px;
  margin-right: 2px;
}
div.timeline-control-block {
  padding-top:2em;
}

.introjs-fixParent.modal,
.introjs-fixParent.modal-dialog {
  position: relative !important;  
}

/* END - Timeline styles */

.dataset-variable__shortname > i {
  margin-left: .36rem;
}
